import React, { ReactNode } from 'react';
import { ActionAfterLogin, useLoginModal } from '~/hooks/useLoginModal';

type LoginModalTriggerProps = {
  children: ReactNode;
  page?: 'initial' | 'login' | 'signup';
  initialTitle?: React.ReactNode | null;
  actionAfterLogin?: ActionAfterLogin;
  className?: string;
};

export function LoginModalTrigger({
  children,
  page = 'initial',
  initialTitle,
  actionAfterLogin,
  className = '',
}: LoginModalTriggerProps) {
  const { openModal } = useLoginModal();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    openModal({ page, actionAfterLogin, initialTitle });
  };

  return (
    <div onClick={handleClick} className={`cursor-pointer ${className}`}>
      {children}
    </div>
  );
}
