import { cn } from '@cardo/lib';
import { Badge, Card } from '@cardo/ui';
import { TripReport } from '@cardo/types';
import { Link } from '@remix-run/react';
import { UserCardLine } from './UserCardLine';

export function getTripReportTypeDetails({
  tripReport,
}: {
  tripReport: TripReport | null;
}) {
  let backgroundImage: string = '';
  let badges: string[] = [];

  if (!tripReport?.attributes) {
    return { backgroundImage, badges };
  }

  const attrs = tripReport.attributes;

  const hasCoverImage = Boolean(
    attrs.coverImage?.data && attrs.coverImage.data.attributes?.url,
  );
  const coverImageUrl = attrs.coverImage?.data?.attributes?.url;

  if (hasCoverImage && coverImageUrl) {
    backgroundImage = `url(${coverImageUrl})`;
  } else {
    switch (attrs.type) {
      case 'hotel': {
        const hotel = attrs.hotel?.data?.attributes;
        const hotelFeatureImage = hotel?.featuredImage?.data?.attributes?.url;
        const hotelCoverImage = hotel?.coverImage?.data?.attributes?.url;
        const imageUrl = hotelFeatureImage || hotelCoverImage;
        backgroundImage = imageUrl ? `url(${imageUrl})` : '';

        const location = hotel?.location?.data?.attributes;
        if (location) {
          const locationParts = [
            location.place,
            location.region,
            location.country,
          ].filter(Boolean);
          if (locationParts.length > 0) {
            badges.push(locationParts.join(', '));
          }
        }

        const rewardsProgram =
          hotel?.hotelGroup?.data?.attributes?.rewardsProgram;
        if (rewardsProgram) {
          badges.push(rewardsProgram);
        }

        break;
      }
      case 'flight': {
        const flight = attrs.flightProduct?.data?.attributes;
        const flightCoverImage = flight?.coverImage?.data?.attributes?.url;
        backgroundImage = flightCoverImage ? `url(${flightCoverImage})` : '';

        const fareClass = flight?.fareClass?.data?.attributes?.name;
        if (fareClass) {
          badges.push(fareClass);
        }

        const airline = flight?.airline?.data?.attributes?.name;
        if (airline) {
          badges.push(airline);
        }

        break;
      }
      case 'airport lounge': {
        const lounge = attrs.airportLounge?.data?.attributes;
        const loungeFeatureImage = lounge?.featuredImage?.data?.attributes?.url;
        const loungeCoverImage = lounge?.coverImage?.data?.attributes?.url;
        const imageUrl = loungeFeatureImage || loungeCoverImage;
        backgroundImage = imageUrl ? `url(${imageUrl})` : '';

        const airport = lounge?.airport?.data?.attributes?.name;
        if (airport) {
          badges.push(airport);
        }

        const loungeName = lounge?.name;
        if (loungeName) {
          badges.push(loungeName);
        }

        break;
      }
    }
  }

  if (badges.length === 0) {
    switch (attrs.type) {
      case 'hotel': {
        const hotel = attrs.hotel?.data?.attributes;

        const location = hotel?.location?.data?.attributes;
        if (location) {
          const locationParts = [
            location.place,
            location.region,
            location.country,
          ].filter(Boolean);
          if (locationParts.length > 0) {
            badges.push(locationParts.join(', '));
          }
        }

        const rewardsProgram =
          hotel?.hotelGroup?.data?.attributes?.rewardsProgram;
        if (rewardsProgram) {
          badges.push(rewardsProgram);
        }
        break;
      }
      case 'flight': {
        const flight = attrs.flightProduct?.data?.attributes;

        const fareClass = flight?.fareClass?.data?.attributes?.name;
        if (fareClass) {
          badges.push(fareClass);
        }

        const airline = flight?.airline?.data?.attributes?.name;
        if (airline) {
          badges.push(airline);
        }
        break;
      }
      case 'airport lounge': {
        const lounge = attrs.airportLounge?.data?.attributes;

        const airport = lounge?.airport?.data?.attributes?.name;
        if (airport) {
          badges.push(airport);
        }

        const loungeName = lounge?.name;
        if (loungeName) {
          badges.push(loungeName);
        }
        break;
      }
    }
  }

  return { backgroundImage, badges };
}

export function TripReportCard({
  className,
  tripReport,
  TravelGoalsSelect,
}: {
  className?: string;
  tripReport: TripReport | null;
  TravelGoalsSelect: React.FC<{
    tripReportId: number;
    className?: string;
    dropDownClassName?: string;
    initialOpen?: boolean;
    instanceIndex?: number;
  }>;
}) {
  const { backgroundImage, badges } = getTripReportTypeDetails({ tripReport });

  return (
    <Card
      className={cn(
        'p-0 flex flex-col border-0 shadow-lg h-full relative',
        className,
      )}
    >
      {tripReport?.id && (
        <TravelGoalsSelect
          tripReportId={tripReport?.id}
          className="absolute top-3 right-3 p-2 rounded-full"
          dropDownClassName="top-4 md:top-12"
        />
      )}
      <Link
        className="no-underline "
        to={`/trip-reports/${tripReport?.attributes.slug}`}
      >
        <div
          className="w-full h-[240px] md:h-[270px] bg-cover bg-center rounded-xl flex items-start justify-end p-4"
          style={{ backgroundImage }}
        />
      </Link>
      <Link
        className="no-underline text-inherit h-full"
        to={`/trip-reports/${tripReport?.attributes.slug}`}
      >
        <div className="flex flex-col gap-2 md:gap-4 p-4 flex-grow h-full">
          <div className="hidden md:flex flex-wrap gap-2 ">
            {badges.map(
              (badge, i) =>
                badge && (
                  <Badge
                    key={badge}
                    color={i ? 'yellow' : 'blue'}
                    className="px-3 py-1 rounded-2xl text-md font-semibold max-w-full"
                  >
                    <p className="truncate">{badge}</p>
                  </Badge>
                ),
            )}
          </div>
          <h2 className="text-lg md:text-xl font-semibold">
            {tripReport?.attributes.title ?? ''}
          </h2>
          <p className="text-sm md:text-base line-clamp-2 md:line-clamp-4">
            {tripReport?.attributes.tagline ?? ''}
          </p>
          {tripReport?.attributes?.influencer &&
            tripReport?.attributes?.influencer.data && (
              <UserCardLine
                className="items-center mt-auto "
                influencer={tripReport?.attributes?.influencer?.data}
              />
            )}
        </div>
      </Link>
    </Card>
  );
}
