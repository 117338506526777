import { cn } from '@cardo/lib';
import { TravelGoal } from '@cardo/types';
import { Button, GoogleIcon } from '@cardo/ui';
import { json, ActionFunctionArgs } from '@remix-run/node';
import { Link, useFetcher, useSearchParams } from '@remix-run/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { useClickAway } from 'react-use';
import { fetchStrapi } from '~/lib/server/api.server';
import { useOptionalUser } from '~/lib/utils';
import { LiaLockSolid } from 'react-icons/lia';
import { getSession } from '~/lib/server/session.server';
import { useUserData } from '~/hooks/useUserData';
import slugify from '@sindresorhus/slugify';
import { IoMailSharp } from 'react-icons/io5';
import { LoginModalTrigger } from '~/components/LoginModalTrigger';

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const session = await getSession(request.headers.get('Cookie'));
  const user = session.get('user');
  const intent = formData.get('intent')?.toString();

  if (intent !== 'save-goals') {
    return json({ error: 'Invalid intent' }, { status: 400 });
  }

  // Extract parameters
  const tripReportId = formData.get('tripReportId')?.toString();
  const newGoalName = formData.get('newGoalName')?.toString() || '';
  const isNewGoalPrivate = formData.get('isNewGoalPrivate') === 'true';
  const selectedGoalIds = formData
    .getAll('selectedGoalIds')
    .map((id) => id.toString());
  // New parameter for goals to remove
  const goalsToRemove = formData
    .getAll('goalsToRemove')
    .map((id) => id.toString());

  if (!tripReportId) {
    return json({ error: 'Trip report ID is required' }, { status: 400 });
  }

  try {
    // Results tracking
    let createdGoal = null;
    let updatedGoals = [];
    let removedFromGoals = [];

    // 1. Create new goal if name provided
    if (newGoalName.trim()) {
      // Check if a goal with this name already exists for this user
      try {
        const existingGoalResponse = await fetchStrapi<TravelGoal[]>(
          '/travel-goal-lists',
          {
            filters: {
              name: {
                $iContains: newGoalName.trim(),
              },
              influencer: {
                id: {
                  $eq: user.user.id,
                },
              },
            },
          },
        );

        if (
          !existingGoalResponse.error &&
          existingGoalResponse.data &&
          existingGoalResponse.data.length > 0
        ) {
          // Check for exact match (case insensitive)
          const exactMatch = existingGoalResponse.data.some(
            (goal) =>
              goal.attributes.name.toLowerCase() ===
              newGoalName.trim().toLowerCase(),
          );

          if (exactMatch) {
            return json(
              { success: false, error: 'A goal with this name already exists' },
              { status: 400 },
            );
          }
        }
      } catch (error) {
        console.error('Error checking for existing goals:', error);
      }
      const slug = slugify(newGoalName);
      const visibility = isNewGoalPrivate ? 'private' : 'public';
      try {
        const response = await fetchStrapi<TravelGoal>(
          '/travel-goal-lists',
          {},
          {
            method: 'POST',
            body: JSON.stringify({
              data: {
                name: newGoalName.trim(),
                slug,
                influencer: user.user.id,
                visibility,
                tripReports: [tripReportId],
              },
            }),
          },
        );

        if (response.error) {
          console.error('Error creating goal:', response.error);
        } else {
          createdGoal = response.data;
        }
      } catch (error) {
        console.error('Exception creating goal:', error);
      }
    }

    // 2. Update existing goals if any selected
    if (selectedGoalIds.length > 0) {
      for (const goalId of selectedGoalIds) {
        try {
          // Get current goal data
          const currentGoal = await fetchStrapi<TravelGoal>(
            `/travel-goal-lists/${goalId}`,
            { populate: { tripReports: { field: ['id'] } } },
          );
          if (currentGoal.error || !currentGoal.data) {
            console.error(`Error fetching goal ${goalId}:`, currentGoal.error);
            continue; // Skip to next goal
          }

          // Extract existing trip reports
          let existingReports = [];
          const reportData = currentGoal.data.attributes?.tripReports;

          if (reportData) {
            // Handle different possible data structures
            if (Array.isArray(reportData)) {
              existingReports = reportData.map((r) =>
                typeof r === 'object' && r !== null ? r.id : r,
              );
            } else if (reportData.data) {
              existingReports = reportData.data.map((item) => item.id);
            }
          }

          // Check if trip report already associated
          if (
            existingReports.some((id) => String(id) === String(tripReportId))
          ) {
            updatedGoals.push(currentGoal.data);
            continue; // Skip to next goal
          }

          // Update the goal with the new trip report
          const updateResponse = await fetchStrapi<TravelGoal>(
            `/travel-goal-lists/${goalId}`,
            {},
            {
              method: 'PUT',
              body: JSON.stringify({
                data: {
                  tripReports: [...existingReports, tripReportId],
                },
              }),
            },
          );

          if (updateResponse.error) {
            console.error(
              `Error updating goal ${goalId}:`,
              updateResponse.error,
            );
          } else {
            updatedGoals.push(updateResponse.data);
          }
        } catch (error) {
          console.error(`Exception processing goal ${goalId}:`, error);
        }
      }
    }

    // 3. Process goals to remove trip report from
    if (goalsToRemove.length > 0) {
      for (const goalId of goalsToRemove) {
        try {
          // Get current goal data
          const currentGoal = await fetchStrapi<TravelGoal>(
            `/travel-goal-lists/${goalId}`,
            { populate: { tripReports: { field: ['id'] } } },
          );
          if (currentGoal.error || !currentGoal.data) {
            console.error(`Error fetching goal ${goalId}:`, currentGoal.error);
            continue; // Skip to next goal
          }

          // Extract existing trip reports
          let existingReports = [];
          const reportData = currentGoal.data.attributes?.tripReports;

          if (reportData) {
            // Handle different possible data structures
            if (Array.isArray(reportData)) {
              existingReports = reportData.map((r) =>
                typeof r === 'object' && r !== null ? r.id : r,
              );
            } else if (reportData.data) {
              existingReports = reportData.data.map((item) => item.id);
            }
          }

          // Filter out the trip report to remove
          const updatedReports = existingReports.filter(
            (id) => String(id) !== String(tripReportId),
          );

          if (updatedReports.length === existingReports.length) {
            continue; // Skip to next goal if no change needed
          }

          // Update the goal without the trip report
          const updateResponse = await fetchStrapi<TravelGoal>(
            `/travel-goal-lists/${goalId}`,
            {},
            {
              method: 'PUT',
              body: JSON.stringify({
                data: {
                  tripReports: updatedReports,
                },
              }),
            },
          );

          if (updateResponse.error) {
            console.error(
              `Error updating goal ${goalId}:`,
              updateResponse.error,
            );
          } else {
            removedFromGoals.push(updateResponse.data);
          }
        } catch (error) {
          console.error(`Exception processing goal ${goalId}:`, error);
        }
      }
    }

    // Return success result
    return json({
      success: true,
      created: createdGoal ? 1 : 0,
      updated: updatedGoals.length,
      removed: removedFromGoals.length,
    });
  } catch (error) {
    console.error('Error in save-goals action:', error);
    return json(
      {
        error:
          error instanceof Error
            ? error.message
            : 'Failed to save travel goals',
      },
      { status: 500 },
    );
  }
}

export function TravelGoalsSelect({
  tripReportId,
  className,
  dropDownClassName,
  initialOpen,
  instanceIndex,
}: {
  tripReportId: number;
  className?: string;
  dropDownClassName?: string;
  initialOpen?: boolean;
  instanceIndex?: number;
}) {
  const actionFetcher = useFetcher<typeof action>();
  const ref = useRef(null);
  const user = useOptionalUser();
  const { tripReportIds, travelGoals } = useUserData();
  const newGoalInputRef = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState<boolean>(() => {
    if (initialOpen) {
      return initialOpen;
    }
    return false;
  });
  const [newGoalName, setNewGoalName] = useState<string>('');
  const [isNewGoalPrivate, setIsNewGoalPrivate] = useState<boolean>(false);
  const [isCreatingNew, setIsCreatingNew] = useState<boolean>(false);
  const [selectedGoalIds, setSelectedGoalIds] = useState<number[]>([]);
  const [goalsToRemove, setGoalsToRemove] = useState<number[]>([]);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const preSelectedGoalIds = useMemo(() => {
    if (!travelGoals) return [];

    return travelGoals
      .filter((goal) => {
        const tripReports = goal.attributes.tripReports;
        if (!tripReports) return false;

        if (tripReports.data && Array.isArray(tripReports.data)) {
          return tripReports.data.some((report) => report.id === tripReportId);
        }
        if (Array.isArray(tripReports)) {
          return tripReports.some((report) => report.id === tripReportId);
        }
        return false;
      })
      .map((goal) => goal.id);
  }, [travelGoals, tripReportId]);

  useEffect(() => {
    if (isMounted && open) {
      setSelectedGoalIds(preSelectedGoalIds);
      setGoalsToRemove([]);
    }
  }, [preSelectedGoalIds, isMounted, open]);

  const cleanupState = useCallback(() => {
    setIsCreatingNew(false);
    setNewGoalName('');
    setSelectedGoalIds([]);
    setGoalsToRemove([]);
  }, []);

  useClickAway(ref, () => {
    setOpen(false);
    cleanupState();
  });

  useEffect(() => {
    if (actionFetcher.data?.success) {
      setOpen(false);
      cleanupState();
    }
  }, [actionFetcher.data, cleanupState]);

  const toggleCreateNew = useCallback(() => {
    setIsCreatingNew((prev) => {
      const newValue = !prev;
      if (newValue) {
        setTimeout(() => newGoalInputRef.current?.focus(), 0);
      } else {
        setNewGoalName('');
      }
      return newValue;
    });
  }, []);

  const handleGoalSelection = useCallback(
    (goalId: number) => {
      // Check if this is a pre-selected goal
      const isPreSelected = preSelectedGoalIds.includes(goalId);

      if (isPreSelected) {
        setSelectedGoalIds((prevIds) => {
          if (prevIds.includes(goalId)) {
            return prevIds.filter((id) => id !== goalId);
          } else {
            return [...prevIds, goalId];
          }
        });

        setGoalsToRemove((prevIds) => {
          if (prevIds.includes(goalId)) {
            return prevIds.filter((id) => id !== goalId);
          } else if (preSelectedGoalIds.includes(goalId)) {
            return [...prevIds, goalId];
          }
          return prevIds;
        });
      } else {
        setSelectedGoalIds((prevIds) =>
          prevIds.includes(goalId)
            ? prevIds.filter((id) => id !== goalId)
            : [...prevIds, goalId],
        );
      }
    },
    [preSelectedGoalIds],
  );

  const handleSave = useCallback(() => {
    const formData = new FormData();
    formData.append('intent', 'save-goals');
    formData.append('tripReportId', tripReportId.toString());

    // Add new goal data if creating one
    if (isCreatingNew && newGoalName.trim()) {
      formData.append('newGoalName', newGoalName.trim());
      formData.append('isNewGoalPrivate', isNewGoalPrivate.toString());
    }

    // Add goals to add the trip report to
    const goalsToAdd = selectedGoalIds.filter(
      (id) => !preSelectedGoalIds.includes(id) || !goalsToRemove.includes(id),
    );

    if (goalsToAdd.length > 0) {
      goalsToAdd.forEach((id) => {
        formData.append('selectedGoalIds', id.toString());
      });
    }

    // Add goals to remove the trip report from
    if (goalsToRemove.length > 0) {
      goalsToRemove.forEach((id) => {
        formData.append('goalsToRemove', id.toString());
      });
    }

    // Submit the form if there's a new goal, newly selected goals, or goals to remove
    if (
      (isCreatingNew && newGoalName.trim()) ||
      goalsToAdd.length > 0 ||
      goalsToRemove.length > 0
    ) {
      actionFetcher.submit(formData, {
        method: 'post',
        action: '/resource/travel-goals',
      });
    }
  }, [
    tripReportId,
    isCreatingNew,
    newGoalName,
    isNewGoalPrivate,
    selectedGoalIds,
    goalsToRemove,
    preSelectedGoalIds,
    actionFetcher,
  ]);

  const isSubmitting = actionFetcher.state === 'submitting';

  const authenticatedAppUrl =
    typeof window !== 'undefined'
      ? window.ENV.AUTHENTICATED_APP_URL
      : process.env.AUTHENTICATED_APP_URL;

  const targetingId = `${tripReportId}${instanceIndex ? `__${instanceIndex}` : ''}`;

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('openTravelGoalsSelect') === targetingId) {
      setOpen(true);
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('openTravelGoalsSelect');
      setSearchParams(newParams, {
        replace: true,
      });
    }
  }, [searchParams, setSearchParams, targetingId]);

  if (!user) {
    return (
      <LoginModalTrigger
        initialTitle={
          <>
            <span className="mr-1">🌎</span> Saving this trip? Sign in to add it
            to your travel goals and plans.
          </>
        }
        actionAfterLogin={{
          queryParams: {
            openTravelGoalsSelect: targetingId,
          },
        }}
      >
        <div
          className={cn(
            'bg-white border-white border-[2px] p-2 rounded-[50%] flex items-center cursor-pointer hover:border-black transition-colors duration-200',
            className,
          )}
          onClick={() => setOpen((prev) => !prev)}
        >
          {tripReportIds && tripReportIds.includes(tripReportId) ? (
            <FaBookmark size={17} className="text-[#0E44AC]" />
          ) : (
            <FaRegBookmark size={17} className="text-black" />
          )}
        </div>
      </LoginModalTrigger>
    );
  }

  return (
    <div className="relative" ref={ref}>
      <div
        className={cn(
          'bg-white border-white border-[2px] p-2 rounded-[50%] flex items-center cursor-pointer hover:border-black transition-colors duration-200',
          tripReportIds &&
            tripReportIds.includes(tripReportId) &&
            'hover:border-[#0E44AC]',
          className,
        )}
        onClick={() => setOpen((prev) => !prev)}
      >
        {tripReportIds && tripReportIds.includes(tripReportId) ? (
          <FaBookmark size={17} className="text-[#0E44AC]" />
        ) : (
          <FaRegBookmark size={17} className="text-black" />
        )}
      </div>

      {isMounted && open && (
        <div
          className={cn(
            'absolute flex font-medium flex-col gap-3 top-0 md:top-full border py-5 px-4 z-10 right-0 min-w-[250px] bg-white rounded-lg shadow-md mt-10 md:mt-2 text-black',
            dropDownClassName,
          )}
        >
          {user ? (
            <>
              <p className="text-lg font-semibold px-1">
                🌎 Save to Travel Goal
              </p>
              <div className="flex flex-col gap-3 max-h-64 overflow-y-auto">
                {Array.isArray(travelGoals) && travelGoals.length > 0 ? (
                  <>
                    {travelGoals.map((goal) => {
                      const isRemoved = goalsToRemove.includes(goal.id);
                      return (
                        <div
                          key={goal.id}
                          className="flex items-start gap-2 p-1 text-sm"
                        >
                          <input
                            type="checkbox"
                            id={`goal-${goal.id}`}
                            checked={
                              selectedGoalIds.includes(goal.id) && !isRemoved
                            }
                            onChange={() => handleGoalSelection(goal.id)}
                            className={cn(
                              'h-5 w-5 rounded border-[1.5px] border-black appearance-none checked:bg-black checked:border-black focus:outline-none focus:ring-0 focus:border-black active:outline-none active:ring-0',
                            )}
                          />
                          <label
                            htmlFor={`goal-${goal.id}`}
                            className={cn(
                              'cursor-pointer flex gap-2 items-center',
                            )}
                          >
                            {goal.attributes.name}
                            {goal.attributes.visibility === 'private' && (
                              <LiaLockSolid className="text-gray-500" />
                            )}
                          </label>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <p className="text-gray-500 text-sm">
                    No travel goals found. Create your first one!
                  </p>
                )}
              </div>
              <div className="flex flex-col w-full gap-2">
                <div className="border-gray-300 border-t-[2px] pt-3 p-1">
                  <div
                    className={cn(
                      'flex items-start gap-2 text-sm relative ',
                      isCreatingNew && 'h-[55px]',
                    )}
                  >
                    <input
                      type="checkbox"
                      id="create-new-goal"
                      checked={isCreatingNew}
                      onChange={toggleCreateNew}
                      className="h-5 w-5 z-10 rounded border-[1.5px] border-black appearance-none checked:bg-black checked:border-black focus:outline-none focus:ring-0 focus:border-black active:outline-none active:ring-0"
                    />
                    {isCreatingNew ? (
                      <div className="flex flex-col absolute top-0">
                        <div className="flex items-center gap-1 w-full pl-7">
                          <input
                            ref={newGoalInputRef}
                            id="new-goal-input"
                            type="text"
                            value={newGoalName}
                            onChange={(e) => setNewGoalName(e.target.value)}
                            placeholder="Enter goal name"
                            className="border border-gray-300 rounded px-2 py-[1px] w-full focus:outline-none focus:ring-0 focus:border-gray-400 text-sm"
                          />
                        </div>
                        <div className="flex items-center gap-2 p-1 pl-0 text-sm w-full mt-1">
                          <div className="relative inline-block w-10 align-middle select-none">
                            <input
                              type="checkbox"
                              id="goal-is-secret"
                              checked={isNewGoalPrivate}
                              onChange={() =>
                                setIsNewGoalPrivate(!isNewGoalPrivate)
                              }
                              className="sr-only peer"
                            />
                            <label
                              htmlFor="goal-is-secret"
                              className="block h-6 w-10 cursor-pointer rounded-full bg-gray-200 peer-checked:bg-black after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-[15px] peer-checked:after:border-white peer-focus:outline-none"
                            ></label>
                          </div>
                          <label
                            htmlFor="goal-is-secret"
                            className="cursor-pointer"
                          >
                            Keep this goal secret
                          </label>
                        </div>
                      </div>
                    ) : (
                      <label
                        htmlFor="create-new-goal"
                        className="text-gray-400 cursor-pointer"
                      >
                        Create new goal
                      </label>
                    )}
                  </div>
                </div>
                {actionFetcher.data?.error && (
                  <p className="text-red-500 text-xs px-1">
                    {actionFetcher.data.error}
                  </p>
                )}

                <Button
                  className="text-sm"
                  primary
                  onClick={handleSave}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Saving...' : 'Save Changes'}
                </Button>
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-6 pb-3">
              <p className="text-lg font-semibold px-1">
                🌎 Log in to Save this Trip Report
              </p>
              <div className="flex flex-col w-full gap-2">
                <Link
                  to={`${authenticatedAppUrl}/signup`}
                  className="text-theme-blue-darkest no-underline"
                >
                  <Button
                    type="submit"
                    className="flex items-center gap-2.5 w-full text-center justify-center"
                  >
                    <IoMailSharp />
                    Connect with Email
                  </Button>
                </Link>
                <div className="flex gap-2 items-center w-full sm:w-96 ">
                  <div className="flex-grow h-px border-b border-gray-400" />
                  <div className="text-gray-500 text-sm">or</div>
                  <div className="flex-grow h-px border-b border-gray-400" />
                </div>
                <form
                  method="post"
                  action={`${authenticatedAppUrl}/auth/google`}
                  className="no-underline w-full sm:w-96 flex flex-col space-y-3"
                >
                  <Button
                    type="submit"
                    className="flex items-center gap-2.5 w-full text-center justify-center"
                  >
                    <GoogleIcon />
                    Connect with Google
                  </Button>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
